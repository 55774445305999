<template>
  <div id="pageCadastroVendedor">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title v-if="this.id" class="subheading">Editar vendedor - {{ this.id }}</v-toolbar-title>
              <v-toolbar-title v-else class="subheading">Novo vendedor</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text v-if="this.id" class="">
              <editar-vendedor></editar-vendedor>
            </v-card-text>
            <v-card-text v-else class="">
              <cadastro-vendedor></cadastro-vendedor>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import CadastroVendedor from "@/components/vendedor/componentCadastroVendedor"
import EditarVendedor from "@/components/vendedor/editarVendedor"
export default {
  components: {
    CadastroVendedor,
    EditarVendedor
  },
  data: () => ({
    id: null
  }),
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
  }
}
</script>

export default class EditarVendedor {
  constructor(
    id = "",
    cpf = "",
    nome = "",
    dtNascimento = "",
    telefone1 = "",
    telefone2 = "",
    rg = "",
    praca = "",
    cep = "",
    cidade = "",
    bairro = "",
    rua = "",
    numero = "",
    situacao = "",
    uf = "",
    totalDivida = 0
  ) {
    this.id = id
    this.cpf = cpf
    this.nome = nome
    this.dataNascimento = dtNascimento
    this.telefone1 = telefone1
    this.telefone2 = telefone2
    this.rg = rg
    this.codPraca = praca
    this.cep = cep
    this.cidade = cidade
    this.bairro = bairro
    this.rua = rua
    this.numero = numero
    this.uf = uf
    this.situacao = situacao
    this.totalDivida = totalDivida
  }
}
